import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Dialog from 'components/Dialog';
import type { ShowroomAppProps } from 'pages/_app';
import type { NextPage } from 'next';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import Layout from 'layouts/Layout';
import TextInput from 'components/TextInput';
import SubmitButton from 'components/SubmitButton';
import axios from 'axios';

interface PageProps extends ShowroomAppProps {}

const LoginPage: NextPage<PageProps> = ({ isAuthenticated }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  // Refs

  // HANDERS
  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const element = event.target as HTMLFormElement
    if(element?.id !== 'loginForm') return
    
    setLoading(true);
    setValid(true);
    try {
      await axios.post('/api/auth/login', { email, password });
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
      setValid(false);
      console.log(error);
    }
  };

  return (
    <>
      <Layout title={'Login'} meta={[]} isAuthenticated={isAuthenticated} noFooter>
        <div className="container mt-4 mb-16">
          <div className="grid w-full gap-4 md:grid-cols-2">
            <div className="relative aspect-square order-2 md:order-1">
              <Image src='/assets/Showroom149655-2_LoggaIn.jpg' alt="" layout="fill" objectFit="cover" priority quality={100} />
            </div>
            <div className="flex flex-col px-4 lg:px-16 order-1 md:order-2">
              <div className="flex flex-1 items-center py-4">
                <form id="loginForm" onSubmit={(e) => handleOnSubmit(e)} className="w-full">
                  <h2 className="heading-h2 mb-5">Logga in</h2>
                  {valid ? '' :
                    <div className="mb-3">Fel användarnamn och/eller lösenord.</div>
                  }
                  <div className="mb-5">
                    <TextInput
                      border
                      hideLabel
                      id="email"
                      type="email"
                      label="E-postadress"
                      placeholder="E-postadress"
                      value={email}
                      error={!valid}
                      set={setEmail}
                    />
                  </div>
                  <div className="mb-5">
                    <TextInput
                      border
                      hideLabel
                      id="password"
                      type="password"
                      label="Ditt lösenord"
                      placeholder="Ditt lösenord"
                      value={password}
                      error={!valid}
                      set={setPassword}
                    />
                  </div>
                  <div className="flex items-center justify-end">
                    <Dialog
                      maxWidth="4xl"
                      button={
                        <button type="button" className="btn-text btn-large pl-0">
                          Glömt ditt lösenord?
                        </button>
                      }>
                        <div className="flex flex-col items-center">
                          <div className="max-w-4xl mt-20 mb-16 mx-8 sm:mx-40">
                            <h2 className="heading-h2">Glömt ditt lösenord?</h2>
                            <ForgotPasswordForm />
                          </div>
                        </div>
                    </Dialog>
                    <SubmitButton form="loginForm" label="Logga in" loading={loading} />
                  </div>
                </form>
              </div>
              <div className="flex flex-row border-t-[1px] border-black border-opacity-10 py-6">
                <p className="font-medium">Har du inget konto?</p>
                <Link href="/signup">
                  <a className="ml-1 font-normal underline">Skapa konto</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LoginPage;
